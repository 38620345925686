<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref, watch, onBeforeMount } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import c3api from "../../c3api";
import { useToast } from "vue-toastification";
import { LicenseManager } from "ag-grid-enterprise";
import { useRouter } from "vue-router";
import {
  saveGridState,
  loadGridState,
  resetGridFilterState,
  resetGridColState,
} from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import Warehouses from "@/components/common/Warehouses.vue";
import useWarehouses from "@/hooks/useWarehouses";
const { getCachedWarehouseFilters } = useWarehouses();

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const loadCount = ref(0);
const router = useRouter();
const GRID_SAVE_KEY = "products";
const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";
const BATCH_SIZE = 100;

let gridApi;
const toast = useToast();

const onGridReady = (params) => {
  gridApi = params.api;
  loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const resetGridFiltersSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const warehouseFiltersChangedCallback = function (whIds) {
  gridApi.onFilterChanged();
};

const defaultColDef = {
  sortable: true,
  filter: "agTextColumnFilter",
  suppressHeaderMenuButton: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
  floatingFilter: true,
};

const numericParamsBase = {
  // type: "numericColumn", // this sets the column to align right
  align: 'left',
  cellDataType: "number",
  filter: "agNumberColumnFilter",
  filterParams: {
    defaultOption: "equals",
    filterOptions: [
      {
        displayKey: "nonZero",
        displayName: "Non-zero",
        numberOfInputs: 0,
        predicate: {},
      },
      "equals",
      "greaterThanOrEqual",
      "lessThanOrEqual",
      "inRange",
      "blank",
    ],
    includeBlanksInLessThan: false,
    inRangeInclusive: true,
  },
};

const numericParams = function (field, headerName, colId = null, noFilter = false) {
  let res = {
    ...structuredClone(numericParamsBase),
    ...{ field: field, colId: colId, headerName: headerName },
  };
  if (noFilter) {
    res.filterParams = {};
    res.filter = false;
    res.floatingFilter = false;
    res.sortable = false;
  }
  return res;
};

const columnDefs = [
  { field: "id", headerName: "Prod #", cellDataType: "text" },
  {
    field: "sku",
    colId: "customer_sku",
    headerName: "SKU",
    cellDataType: "text",
  },
  { field: "client.name", headerName: "Client", cellDataType: "text" },
  { field: "upc", headerName: "UPC", cellDataType: "text" },
  { field: "name", headerName: "Name", cellDataType: "text" },

  { field: "units", headerName: "Units", cellDataType: "text" },
  numericParams("counts.on_hand", "On Hand", "tenant_inventory_caches.on_hand"),
  numericParams("counts.allocated", "Allocated", "tenant_inventory_caches.allocated"),
  numericParams("counts.inbound", "Inbound", "tenant_inventory_caches.inbound"),
  numericParams("counts.available", "Available", "tenant_inventory_caches.available"),

  { field: "units1", headerName: "Units", cellDataType: "text" },
  numericParams("on_hand1", "On Hand", true),
  numericParams("allocated1", "Allocated", true),
  numericParams("inbound1", "Inbound", true),
  numericParams("available1", "Available", true),

  { field: "units2", headerName: "Units", cellDataType: "text" },
  numericParams("on_hand2", "On Hand", true),
  numericParams("allocated2", "Allocated", true),
  numericParams("inbound2", "Inbound", true),
  numericParams("available2", "Available", true),
];

const advancedFilterKeys = [
  "number",
  "customer_sku",
  "client.name",
  "upc",
  "name",
  "counts.on_hand",
  "counts.allocated",
  "counts.inbound",
  "counts.available",
];

const buildQueryUrl = function (params, csv = false) {
  let whIds = getCachedWarehouseFilters(PRODUCT_EXTERNAL_FILTERS_KEY);

  if (whIds.length < 1) whIds = [1];

  const urlParams = {
    warehouses: whIds,
    sortModel: params.request.sortModel,
    startRow: params.request.startRow,
    endRow: params.request.endRow,
    batch_size: BATCH_SIZE,
  };

  const filterParams = {};
  if (params.request.filterModel) {
    for (let [k, v] of Object.entries(params.request.filterModel)) {
      if (advancedFilterKeys.includes(k)) {
        filterParams[k] = v;
      } else {
        urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
      }
    }
  }

  urlParams.filter = filterParams;
  if (typeof params.request.startRow !== "undefined") {
    urlParams.start_row = params.request.startRow;
    urlParams.limit = params.request.endRow - params.request.startRow;
  }
  if (csv === true) {
    urlParams.format = "csv";
  }

  // Handle sorting
  if (params.request.sortModel.length > 0) {
    const { colId, sort } = params.request.sortModel[0];
    urlParams.sort = colId;
    urlParams.sort_dir = sort;
  }

  if (!csv && params.request.sortModel.length > 0) {
    const { colId, sort } = params.request.sortModel[0];
    urlParams.sort = colId;
    urlParams.sort_dir = sort;
  }

  return urlParams;
};

const rowDoubleClicked = function (event) {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/products/${event.data.id}`;
  } else {
    console.log("Row clicked does not have an event.data.id!");
    console.log(event);
  }
};

const connectDatasource = {
  async getRows(params) {
    loadCount.value++;
    gridApi.showLoadingOverlay();
    const urlParams = buildQueryUrl(params);
    try {
      const response = await c3api.get("/products", { params: urlParams });
      const data = response.data;
      params.success({
        rowData: data.data,
        rowCount: data.total_count,
      });
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.data.join(". "));
      params.fail();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        gridApi.hideOverlay();
      }
    }
  },
  maxConcurrentDatasourceRequests: 1,
};

const gridOptions = {
  rowModelType: "serverSide",
  serverSideDatasource: connectDatasource,
  columnDefs: columnDefs,
  cacheBlockSize: 100,
  blockLoadDebounceMillis: 500,
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  dataTypeDefinitions: {
    float: {
      extendsDataType: "number",
      baseDataType: "number",
      valueFormatter: (params) =>
        params.value == null ? "0.00" : parseFloat(params.value).toFixed(2),
    },
  },
  getRowId: (params) => {
    return params.data.id;
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
};
</script>

<template>
  <Warehouses
    :callBack="warehouseFiltersChangedCallback"
    :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY"
  />

  <div class="d-flex justify-end mx-4 w-full mt-4">
    <div class="align-center">
      <v-btn class="" variant="text" @click="resetGridFiltersSettings"
        >Reset Filters</v-btn
      >
      <v-btn class="mr-2" variant="text" @click="resetGridColSettings"
        >Reset Columns</v-btn
      >
    </div>
  </div>
  <AgGridVue
    style="width: 100%; height: 100%"
    :class="themeStore.agTheme"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :gridOptions="gridOptions"
    @state-updated="onStateUpdated"
    @grid-ready="onGridReady"
    @rowDoubleClicked="rowDoubleClicked"
  ></AgGridVue>
</template>
